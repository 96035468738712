import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import BulkDeleteLectureForm from "@/components/app/lectures/BulkDeleteLectureForm";
import CreateOrEditLectureForm from "@/components/app/lectures/CreateOrEditLectureForm";
import DeleteLectureForm from "@/components/app/lectures/DeleteLectureForm";
import AdvanceTable from "@/components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "@/components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "@/components/common/advance-table/AdvanceTableWrapper";
import FalconCardFooterLink from "@/components/common/FalconCardFooterLink";
import IconButton from "@/components/common/IconButton";
import Loading from "@/components/extra/Loading";
import {
    buildActionBarColumn,
    buildIdColumn,
    buildImageColumn,
    buildRefColumn,
    buildTextColumn,
    buildYoutubeThumbnailColumn,
} from "@/components/shared/TableBuilder";
import useLecture from "@/hooks/biz/useLecture";
import useDebounce from "@/hooks/useDebounce";
import usePostCallback from "@/hooks/usePostCallback";
import { delayExecute, execute } from "@/utils/common";
import useCourse from "@/hooks/biz/useCourse";
import FormDialog from "@/components/shared/FormDialog";

const actions = [
    { code: "edit", name: "Sửa", variant: "falcon-default", icon: "edit" },
    { code: "editor", name: "Bài kiểm tra", variant: "primary", icon: "edit" },
    { code: "delete", name: "Xoá", variant: "danger", icon: "trash" },
];

const buildTableColumns = ({
    data = [],
    selectedData = [],
    refData = { courses: [] },
    callback,
}) => {
    return [
        buildIdColumn({
            accessor: "id",
            data: data,
            selectedData: selectedData,
            callback: callback,
        }),
        buildTextColumn({ accessor: "title", title: "Tên Bài giảng" }),
        buildTextColumn({ accessor: "topic", title: "Chủ đề" }),
        buildYoutubeThumbnailColumn({
            accessor: "videoUrl",
            title: "Video (Youtube)",
        }),
        buildRefColumn({
            accessor: "courseId",
            title: "Khoá học",
            refData: refData?.courses,
            refAccessor: "courseName",
        }),
        buildTextColumn({ accessor: "orderNum", title: "Thứ tự" }),
        buildActionBarColumn({ actions: actions, callback: callback }),
    ];
};

const TableHeader = ({ title, selectedData = [], callback }) => {
    const callbackRef = useRef(null);
    const [keyword, setKeyword] = useState("");
    const debouncedKeyword = useDebounce(keyword, 200);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (_.isString(debouncedKeyword))
            (async () =>
                execute(callbackRef.current, {
                    action: "search",
                    payload: debouncedKeyword,
                }))();
    }, [debouncedKeyword]);

    const checkedCount = useMemo(() => {
        return selectedData?.length || 0;
    }, [selectedData]);

    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
                <Form className="position-relative d-none d-sm-inline-block ms-2">
                    <Form.Control
                        type="search"
                        placeholder="Nhập từ khoá tìm kiếm"
                        size="sm"
                        aria-label="Tìm kiếm"
                        className="rounded search-input ps-4"
                        value={keyword}
                        onChange={e => {
                            e.preventDefault();
                            setKeyword(e.target.value);
                        }}
                    />
                    <FontAwesomeIcon
                        icon="search"
                        className="fs-10 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
                    />
                </Form>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        (async () =>
                            execute(callback, {
                                action: "search",
                                payload: debouncedKeyword,
                            }))();
                    }}
                    className="d-none d-sm-inline-block ms-0"
                    size="sm"
                    variant="link"
                >
                    <FontAwesomeIcon icon="filter" className="fs-9 text-700" />
                </Button>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {checkedCount > 0 ? (
                    <div className="d-flex">
                        <Button
                            type="button"
                            variant="danger"
                            size="sm"
                            className="ms-2"
                            onClick={e => {
                                e.preventDefault();
                                (async () =>
                                    execute(callback, {
                                        action: "bulk_delete",
                                        payload: selectedData || [],
                                    }))();
                            }}
                        >
                            {`Xoá (${checkedCount})`}
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            onClick={e => {
                                e.preventDefault();
                                (async () =>
                                    execute(callback, { action: "create" }))();
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">
                                Tạo mới
                            </span>
                        </IconButton>
                    </div>
                )}
            </Col>
        </Row>
    );
};

const LectureManagement = ({ limit = 0 }) => {
    const navigate = useNavigate();

    const editFormRef = useRef(null);
    const deleteFormRef = useRef(null);
    const bulkDeleteFormRef = useRef(null);

    const editFormDialogRef = useRef(null);
    const deleteFormDialogRef = useRef(null);
    const bulkDeleteFormDialogRef = useRef(null);

    const {
        searchLectureProcessing,
        searchLectureErrorMessage,
        searchLecture,
        lecturesPagination: {
            content: data,
            totalPages,
            totalElements,
            last,
            first,
            size,
            number: pageIndex,
        },
    } = useLecture();

    const [selectedData, setSelectedData] = useState([]);

    const addSelectedData = useCallback((...items) => {
        const ids = items?.map(item => item.id) || [];
        setSelectedData(prevState => {
            return [
                ...prevState.filter(item => !ids.includes(item.id)),
                ...Object.freeze(items || []),
            ];
        });
    }, []);

    const removeSelectedData = useCallback((...items) => {
        const ids = items?.map(item => item.id);
        setSelectedData(prevState => {
            return [...prevState.filter(item => !ids.includes(item.id))];
        });
    }, []);

    const addAllSelectedData = useCallback(() => {
        setSelectedData(prevState => Object.freeze(data || []));
    }, [data]);

    const clearAllSelectedData = useCallback(() => {
        setSelectedData(prevState => []);
    }, []);

    useEffect(() => {
        setSelectedData(prevState => []);
    }, [data]);

    const [pageSize, setPageSize] = useState(15);
    const [filter, setFilter] = useState(undefined);
    const filterOf = useCallback((keyword = "") => {
        return { title: keyword };
    }, []);

    const goToPage = useCallback(
        async (page, inSilentMode = false) => {
            return await searchLecture(
                filter,
                { page: page, size: limit > 0 ? limit : pageSize, sort: "" },
                inSilentMode,
            );
        },
        [searchLecture, filter, limit, pageSize],
    );

    useEffect(() => {
        //handle filter changed
        if (filter) (async () => goToPage(0))();
    }, [filter, goToPage]);

    const refreshPage = useCallback(
        async (inSilentMode = false) => {
            return await goToPage(pageIndex, inSilentMode);
        },
        [goToPage, pageIndex],
    );

    const [currentLecture, setCurrentLecture] = useState(null);

    const handleCallback = useCallback(
        ({ action, payload }) => {
            switch (action) {
                case "check":
                    addSelectedData(payload);
                    break;
                case "checkAll":
                    addAllSelectedData();
                    break;
                case "uncheck":
                    removeSelectedData(payload);
                    break;
                case "uncheckAll":
                    clearAllSelectedData();
                    break;
                case "search":
                    setFilter(filterOf(payload));
                    break;
                case "create":
                    setCurrentLecture(null);
                    (async () =>
                        execute(editFormDialogRef?.current?.showDialog))();
                    break;
                case "edit":
                    setCurrentLecture(payload);
                    (async () =>
                        execute(editFormDialogRef?.current?.showDialog))();
                    break;
                case "editor":
                    navigate(`/lectures/${payload?.id}/quizzes`);
                    break;
                case "delete":
                    setCurrentLecture(payload);
                    (async () =>
                        execute(deleteFormDialogRef?.current?.showDialog))();
                    break;
                case "bulk_delete":
                    setCurrentLecture(null);
                    (async () =>
                        execute(
                            bulkDeleteFormDialogRef?.current?.showDialog,
                        ))();

                    break;
                default:
                    break;
            }
        },
        [
            addAllSelectedData,
            addSelectedData,
            clearAllSelectedData,
            filterOf,
            removeSelectedData,
        ],
    );

    const {
        searchCourse,
        coursesPagination: { content: courses },
    } = useCourse();

    const columns = useMemo(() => {
        return buildTableColumns({
            data: data,
            selectedData: selectedData,
            refData: { courses: courses },
            callback: handleCallback,
        });
    }, [data, selectedData, courses, handleCallback]);

    usePostCallback(() => searchCourse({}, { page: 0, size: 0, sort: "" }));

    return (
        <>
            <FormDialog
                ref={editFormDialogRef}
                title={`${currentLecture ? "Cập nhật" : "Tạo mới"} Bài giảng`}
                cancelButtonLabel={"Đóng"}
                submitButtonLabel={"Lưu"}
                onSubmit={() => {
                    return editFormRef.current.submit();
                }}
                onClose={result => {
                    if (result) {
                        delayExecute(300)(() => refreshPage(true));
                    }
                }}
            >
                <CreateOrEditLectureForm
                    ref={editFormRef}
                    data={currentLecture}
                />
            </FormDialog>
            <FormDialog
                ref={deleteFormDialogRef}
                title={`Xoá Bài giảng`}
                cancelButtonLabel={"Đóng"}
                submitButtonLabel={"Xoá"}
                submitButtonIcon={"trash"}
                submitButtonVariant={"danger"}
                onSubmit={() => {
                    return deleteFormRef.current.submit();
                }}
                onClose={result => {
                    if (result) {
                        delayExecute(300)(() => refreshPage(true));
                    }
                }}
            >
                <DeleteLectureForm ref={deleteFormRef} data={currentLecture} />
            </FormDialog>

            <FormDialog
                ref={bulkDeleteFormDialogRef}
                title={`Xác nhận xoá Bài giảng`}
                cancelButtonLabel={"Đóng"}
                submitButtonLabel={"Xoá"}
                submitButtonIcon={"trash"}
                submitButtonVariant={"danger"}
                onSubmit={() => {
                    return bulkDeleteFormRef.current.submit();
                }}
                onClose={result => {
                    if (result) {
                        delayExecute(300)(() => refreshPage(true));
                    }
                }}
            >
                <BulkDeleteLectureForm
                    ref={bulkDeleteFormRef}
                    selectedData={selectedData}
                />
            </FormDialog>
            <AdvanceTableWrapper
                columns={columns}
                data={data || []}
                sortable={false}
                pagination={false}
                selection={false}
                perPage={pageSize}
            >
                <Card className="h-100">
                    <Card.Header>
                        <TableHeader
                            title={"Quản lý Bài giảng"}
                            selectedData={selectedData}
                            callback={handleCallback}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-body-tertiary text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                striped: true,
                                className: "fs-11 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    {limit > 0 ? (
                        <FalconCardFooterLink
                            title="Quản lý Bài giảng"
                            size="sm"
                            to={"/lectures"}
                        />
                    ) : (
                        <Card.Footer>
                            {searchLectureProcessing ? (
                                <Col className="d-flex justify-content-center">
                                    <Loading size={48} />
                                </Col>
                            ) : (
                                <AdvanceTablePagination
                                    canPreviousPage={!first}
                                    canNextPage={!last}
                                    previousPage={() =>
                                        goToPage(
                                            (pageIndex || 0) > 1
                                                ? (pageIndex || 0) - 1
                                                : 0,
                                        )
                                    }
                                    nextPage={() =>
                                        goToPage(
                                            (pageIndex || 0) <
                                                (totalPages || 0) - 1
                                                ? (pageIndex || 0) + 1
                                                : (totalPages || 0) - 1,
                                        )
                                    }
                                    pageCount={totalPages || 0}
                                    pageIndex={pageIndex || 0}
                                    gotoPage={goToPage}
                                />
                            )}
                        </Card.Footer>
                    )}
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default LectureManagement;
