import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import {
    answerTypes,
    dataFormats,
    questionTypes,
} from "@/components/app/questions/QuestionManagement";
import {
    fromAnswers,
    getAnswerNumbers,
    toAnswers,
} from "@/components/app/questions/utils";
import FormAnswerInput from "@/components/shared/FormAnswerInput";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormNumberInput from "@/components/shared/FormNumberInput";
import FormSelectInput from "@/components/shared/FormSelectInput";
import FormTextInput from "@/components/shared/FormTextInput";
import useQuestion from "@/hooks/biz/useQuestion";
import usePostCallback from "@/hooks/usePostCallback";

const CreateOrEditQuestionForm = forwardRef((props, ref) => {
    const { data } = props || {};
    const validationSchema = yup.object().shape({
        title: yup.string().required("Nội dung câu hỏi không được để trống."),
        questionType: yup.number().min(1, "Loại câu hỏi không được để trống."),
        point: yup.number().min(0, "Điểm không hợp lệ."),
        questionCode: yup.string().nullable(),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
            ...fromAnswers(data?.answers),
        },
    });

    const {
        reset,
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const [answerNumbers, setAnswerNumbers] = useState([1]);
    const [maxAnswerNumber, setMaxAnswerNumber] = useState(1);

    useEffect(() => {
        const numbers = getAnswerNumbers(data?.answers || [{}]);
        setAnswerNumbers(numbers);
        setMaxAnswerNumber(Math.max(...numbers));
    }, [data?.answers]);

    const removeAnswerNumber = useCallback(num => {
        setAnswerNumbers(prevState => prevState.filter(item => item !== num));
    }, []);

    const addAnswerNumber = useCallback(() => {
        setMaxAnswerNumber(prevState => prevState + 1);
    }, []);

    useEffect(() => {
        setAnswerNumbers(prevState => {
            if (prevState.includes(maxAnswerNumber)) return prevState;
            return [...prevState, maxAnswerNumber];
        });
    }, [maxAnswerNumber]);

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            (async () => trigger())();
        },
        [setValue, trigger],
    );

    const {
        createQuestionProcessing,
        createQuestionErrorMessage,
        createQuestion,
        updateQuestionProcessing,
        updateQuestionErrorMessage,
        updateQuestion,
        getQuestionProcessing,
        getQuestionErrorMessage,
        getQuestion,
        question,
    } = useQuestion();

    const disabled = useMemo(() => {
        return createQuestionProcessing || updateQuestionProcessing;
    }, [createQuestionProcessing, updateQuestionProcessing]);

    const errorMessage = useMemo(() => {
        return createQuestionErrorMessage || updateQuestionErrorMessage;
    }, [createQuestionErrorMessage, updateQuestionErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues();
            const {
                id,
                quizId,
                title,
                questionCode,
                questionType,
                point,
                status,
            } = values || {};
            const answers = toAnswers(values, answerNumbers);
            const data = {
                id,
                quizId,
                title,
                questionCode,
                questionType,
                point,
                status,
                answers,
            };
            return id && id > 0 ? updateQuestion(data) : createQuestion(data);
        } else {
            return { error: errors };
        }
    }, [answerNumbers, createQuestion, errors, getValues, updateQuestion]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
            addAnswerNumber: addAnswerNumber,
        }),
        [addAnswerNumber, onSubmit],
    );

    useEffect(() => {
        reset({ ...question, ...fromAnswers(question?.answers) });
        (async () => trigger())();
    }, [question, reset, trigger]);

    useEffect(() => {
        reset({
            ...data,
            ...fromAnswers(data?.answers),
        });
    }, [reset, data]);

    usePostCallback(trigger, () => {
        if (data?.id) return getQuestion(data?.id);
    });

    const buildFormAnswerInputExt = useCallback(
        ({ answerNumber = 1, deleteCallback }) => {
            return (
                <Row className="gx-2 gy-3">
                    <Col md="6">
                        <FormAnswerInput
                            register={register}
                            errors={errors}
                            accessor={`answer${answerNumber}Content`}
                            title={`Đáp án ${answerNumber}`}
                            value={getValues(`answer${answerNumber}Content`)}
                            callback={handleValueChange(
                                `answer${answerNumber}Content`,
                            )}
                            extAccessor={`answer${answerNumber}Correct`}
                            extTitle={"Sai/Đúng"}
                            extValue={getValues(`answer${answerNumber}Correct`)}
                            extCallback={handleValueChange(
                                `answer${answerNumber}Correct`,
                            )}
                            placeholder={`Nhập Đáp án ${answerNumber}`}
                            required={true}
                            disabled={disabled}
                            deleteCallback={deleteCallback}
                        />
                    </Col>
                    <Col md="2">
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={`answer${answerNumber}Type`}
                            title={"Loại"}
                            value={getValues(`answer${answerNumber}Type`)}
                            nonValue={0}
                            nonLabel={"-"}
                            defaultValue={1}
                            refData={answerTypes}
                            refAccessor={"name"}
                            callback={handleValueChange(
                                `answer${answerNumber}Type`,
                            )}
                            placeholder={"Loại"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md="2">
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={`answer${answerNumber}DataFormat`}
                            title={"Định dạng"}
                            value={getValues(`answer${answerNumber}DataFormat`)}
                            nonValue={0}
                            nonLabel={"-"}
                            defaultValue={0}
                            refData={dataFormats}
                            refAccessor={"name"}
                            callback={handleValueChange(
                                `answer${answerNumber}DataFormat`,
                            )}
                            placeholder={"Định dạng"}
                            required={false}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md="2">
                        <FormNumberInput
                            register={register}
                            errors={errors}
                            accessor={`answer${answerNumber}DataMaxLength`}
                            title={"Độ dài dữ liệu"}
                            value={getValues(
                                `answer${answerNumber}DataMaxLength`,
                            )}
                            defaultValue={1}
                            callback={handleValueChange(
                                `answer${answerNumber}DataMaxLength`,
                            )}
                            placeholder={"Nhập Độ dài dữ liệu"}
                            required={false}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            );
        },
        [disabled, errors, getValues, handleValueChange, register],
    );

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />

                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"title"}
                            title={"Nội dung câu hỏi"}
                            value={getValues("title")}
                            callback={handleValueChange("title")}
                            placeholder={"Nhập Nội dung câu hỏi"}
                            required={true}
                            disabled={disabled}
                        />
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormSelectInput
                                    register={register}
                                    errors={errors}
                                    accessor={"questionType"}
                                    title={"Loại câu hỏi"}
                                    value={getValues("questionType")}
                                    nonValue={0}
                                    defaultValue={1}
                                    refData={questionTypes}
                                    refAccessor={"name"}
                                    callback={handleValueChange("questionType")}
                                    placeholder={"Loại câu hỏi"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="4">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"questionCode"}
                                    title={"Mã câu hỏi"}
                                    value={getValues("questionCode")}
                                    callback={handleValueChange("questionCode")}
                                    placeholder={"Nhập Mã câu hỏi"}
                                    required={false}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="2">
                                <FormNumberInput
                                    register={register}
                                    errors={errors}
                                    accessor={"point"}
                                    title={"Điểm"}
                                    value={getValues("point")}
                                    defaultValue={1}
                                    callback={handleValueChange("point")}
                                    placeholder={"Nhập Điểm"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>

                        {answerNumbers?.map(answerNumber => {
                            return buildFormAnswerInputExt({
                                answerNumber: answerNumber,
                                deleteCallback: () =>
                                    removeAnswerNumber(answerNumber),
                            });
                        })}
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

CreateOrEditQuestionForm.displayName = "CreateOrEditQuestionForm";

export default CreateOrEditQuestionForm;
