import { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import useProcessing from "@/hooks/useProcessing";
import useProtectedHttp from "@/hooks/useProtectedHttp";
import { isNotEmptyArray } from "@/utils/common";

const useResource = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [resource, setResource] = useState(undefined);
    const [resourcesPagination, setResourcesPagination] = useState({});

    //create
    const createResourceProcessing = useMemo(() => {
        return !!processing?.createResource;
    }, [processing?.createResource]);

    const createResourceErrorMessage = useMemo(() => {
        return errorMessage?.createResource;
    }, [errorMessage?.createResource]);

    const createResource = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createResource",
                async () => {
                    try {
                        if (!inSilentMode) setResource(undefined);
                        const result = await post(`/v1/resources/create`, data);
                        setResource(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //search
    const searchResourceProcessing = useMemo(() => {
        return !!processing?.searchResource;
    }, [processing?.searchResource]);

    const searchResourceErrorMessage = useMemo(() => {
        return errorMessage?.searchResource;
    }, [errorMessage?.searchResource]);

    const searchResource = useCallback(
        (
            data = {},
            options = { page: 0, size: 10, sort: "" },
            inSilentMode = false,
        ) => {
            return process(
                "searchResource",
                async () => {
                    try {
                        const { page, size, sort } = options || {};
                        if (!inSilentMode) setResourcesPagination({});
                        const result =
                            size > 0
                                ? await post(
                                      `/v1/resources/search?page=${page}&size=${size}&sort=${sort}`,
                                      data,
                                  )
                                : await get(`/v1/resources`);
                        setResourcesPagination(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, get],
    );

    //get
    const getResourceProcessing = useMemo(() => {
        return !!processing?.getResource;
    }, [processing?.getResource]);

    const getResourceErrorMessage = useMemo(() => {
        return errorMessage?.getResource;
    }, [errorMessage?.getResource]);

    const getResource = useCallback(
        (id, options = { includes: [] }, inSilentMode = false) => {
            return process(
                "getResource",
                async () => {
                    try {
                        const { includes } = options || {};
                        if (!inSilentMode) setResource(undefined);
                        const result = await get(
                            `/v1/resources/${id}?includes=${(includes || []).join(",")}`,
                        );
                        setResource(result);

                        return Promise.resolve(result?.resource);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get],
    );

    //delete
    const deleteResourceProcessing = useMemo(() => {
        return !!processing?.deleteResource;
    }, [processing?.deleteResource]);

    const deleteResourceErrorMessage = useMemo(() => {
        return errorMessage?.deleteResource;
    }, [errorMessage?.deleteResource]);

    const deleteResource = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteResource",
                async () => {
                    try {
                        if (!inSilentMode) setResource(undefined);
                        const result = await post(`/v1/resources/delete`, data);
                        setResource(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //update
    const updateResourceProcessing = useMemo(() => {
        return !!processing?.updateResource;
    }, [processing?.updateResource]);

    const updateResourceErrorMessage = useMemo(() => {
        return errorMessage?.updateResource;
    }, [errorMessage?.updateResource]);

    const updateResource = useCallback(
        (data, options = { updateFields: [] }, inSilentMode = false) => {
            return process(
                "updateResource",
                async () => {
                    try {
                        const { id } = data || {};
                        const { updateFields } = options || {};
                        const result = isNotEmptyArray(updateFields)
                            ? await post(`/v1/resources/${id}/patch`, {
                                  data,
                                  updateFields,
                              })
                            : await post(`/v1/resources/${id}/update`, data);
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    return {
        createResourceProcessing,
        createResourceErrorMessage,
        createResource,
        searchResourceProcessing,
        searchResourceErrorMessage,
        searchResource,
        resourcesPagination,
        getResourceProcessing,
        getResourceErrorMessage,
        getResource,
        resource,
        deleteResourceProcessing,
        deleteResourceErrorMessage,
        deleteResource,
        updateResourceProcessing,
        updateResourceErrorMessage,
        updateResource,
    };
};

useResource.propTypes = {
    processing: PropTypes.shape({
        createResource: PropTypes.bool,
        searchResource: PropTypes.bool,
        getResource: PropTypes.bool,
        deleteResource: PropTypes.bool,
        updateResource: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createResource: PropTypes.string,
        searchResource: PropTypes.string,
        getResource: PropTypes.string,
        deleteResource: PropTypes.string,
        updateResource: PropTypes.bool,
    }),
};

export default useResource;
