import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useResource from "@/hooks/biz/useResource";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormTextInput from "@/components/shared/FormTextInput";
import FormSelectInput from "@/components/shared/FormSelectInput";
import FormNumberInput from "@/components/shared/FormNumberInput";
import FormYoutubeUrlInput from "@/components/shared/FormYoutubeUrlInput";
import { resourceGroups } from "@/components/app/resources/utils";

const DeleteResourceForm = forwardRef((props, ref) => {
    const { data } = props || {};

    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Video Tư liệu không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const {
        deleteResourceProcessing,
        deleteResourceErrorMessage,
        deleteResource,
    } = useResource();

    const disabled = useMemo(() => {
        return true;
    }, []);

    const errorMessage = useMemo(() => {
        return deleteResourceErrorMessage;
    }, [deleteResourceErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues() || {};
            return deleteResource([values["id"]]);
        } else {
            return { error: errors };
        }
    }, [deleteResource, errors, getValues]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormYoutubeUrlInput
                            register={register}
                            errors={errors}
                            accessor={"fileUrl"}
                            title={"Video Tư liệu (Youtube)"}
                            value={getValues("fileUrl")}
                            callback={handleValueChange("fileUrl")}
                            placeholder={
                                "Nhập hoặc Copy/Paste đường dẫn Video Youtube"
                            }
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"note"}
                            title={"Ghi chú"}
                            value={getValues("note")}
                            callback={handleValueChange("note")}
                            placeholder={"Nhập Ghi chú"}
                            required={false}
                            disabled={disabled}
                        />

                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"resourceGroup"}
                            title={"Nhóm"}
                            value={getValues("resourceGroup")}
                            nonValue={""}
                            defaultValue={"movie"}
                            refData={resourceGroups}
                            refAccessor={"resourceGroupName"}
                            callback={handleValueChange("resourceGroup")}
                            placeholder={"Nhóm"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormNumberInput
                            register={register}
                            errors={errors}
                            accessor={"orderNum"}
                            title={"Số Thứ tự"}
                            value={getValues("orderNum")}
                            callback={handleValueChange("orderNum")}
                            placeholder={"Nhập Số Thứ tự"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default DeleteResourceForm;
