import React, { useCallback, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SimpleBarReact from "simplebar-react";

import CreateOrEditQuestionForm from "@/components/app/questions/CreateOrEditQuestionForm";
import DeleteQuestionForm from "@/components/app/questions/DeleteQuestionForm";
import {
    QuestionContentBody,
    QuestionContentHeader,
} from "@/components/app/quizzes/QuestionContent";
import QuestionItem from "@/components/app/quizzes/QuestionItem";
import Flex from "@/components/common/Flex";
import IconButton from "@/components/common/IconButton";
import FormDialog from "@/components/shared/FormDialog";
import useQuiz from "@/hooks/biz/useQuiz";
import useQuizQuestion from "@/hooks/biz/useQuizQuestion";
import useDebounce from "@/hooks/useDebounce";
import usePostCallback from "@/hooks/usePostCallback";
import {
    delayExecute,
    execute,
    getFirstItemOfArray,
    isNotEmptyArray,
} from "@/utils/common";

const QuizEditorHeader = ({ title, subTitle, callback }) => {
    const callbackRef = useRef(null);
    const [keyword, setKeyword] = useState("");
    const debouncedKeyword = useDebounce(keyword, 200);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (_.isString(debouncedKeyword))
            (async () =>
                execute(callbackRef.current, {
                    action: "search",
                    payload: debouncedKeyword,
                }))();
    }, [debouncedKeyword]);

    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
                    {title} ({subTitle})
                </h5>
                <Form className="position-relative d-none d-sm-inline-block ms-2">
                    <Form.Control
                        type="search"
                        placeholder="Nhập từ khoá tìm kiếm"
                        size="sm"
                        aria-label="Tìm kiếm"
                        className="rounded search-input ps-4"
                        value={keyword}
                        onChange={e => {
                            e.preventDefault();
                            setKeyword(e.target.value);
                        }}
                    />
                    <FontAwesomeIcon
                        icon="search"
                        className="fs-10 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
                    />
                </Form>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        (async () =>
                            execute(callback, {
                                action: "search",
                                payload: debouncedKeyword,
                            }))();
                    }}
                    className="d-none d-sm-inline-block ms-0"
                    size="sm"
                    variant="link"
                >
                    <FontAwesomeIcon icon="filter" className="fs-9 text-700" />
                </Button>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div className="d-flex">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={e => {
                            e.preventDefault();
                            (async () =>
                                execute(callback, { action: "create" }))();
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            Tạo mới
                        </span>
                    </IconButton>
                </div>
            </Col>
        </Row>
    );
};
QuizEditorHeader.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    callback: PropTypes.func,
};

const QuizEditorBody = ({ data, callback }) => {
    const { quizId = 0, questions = [] } = data;

    const [hideSidebar, setHideSidebar] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const editFormRef = useRef(null);
    const deleteFormRef = useRef(null);

    const editFormDialogRef = useRef(null);
    const deleteFormDialogRef = useRef(null);

    const [currentQuestion, setCurrentQuestion] = useState(undefined);

    const handleSelect = index => {
        setHideSidebar(false);
        setActiveIndex(index);
        setCurrentQuestion(() => ({
            ...questions[index],
            quizId: quizId,
        }));
    };

    const handleCallback = useCallback(({ action }) => {
        switch (action) {
            case "edit":
                return execute(editFormDialogRef?.current?.showDialog);
            case "delete":
                return execute(deleteFormDialogRef?.current?.showDialog);
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (isNotEmptyArray(questions)) {
            const question = questions[activeIndex] || questions[0];
            setCurrentQuestion(prevState => {
                if (!prevState) return question;
                else return prevState;
            });
        } else {
            setCurrentQuestion(() => undefined);
        }
    }, [questions, activeIndex]);

    return (
        <>
            <FormDialog
                ref={editFormDialogRef}
                title={`${currentQuestion ? "Cập nhật" : "Tạo mới"} Câu hỏi`}
                size={"lg"}
                cancelButtonLabel={"Đóng"}
                submitButtonLabel={"Lưu"}
                secondarySubmitButtonLabel={"Thêm câu trả lời"}
                secondarySubmitButtonIcon={"add"}
                onSecondarySubmit={() => {
                    return editFormRef.current.addAnswerNumber();
                }}
                onSubmit={() => {
                    return execute(editFormRef.current.submit);
                }}
                onClose={result => {
                    if (result) {
                        setCurrentQuestion(() => undefined);
                        return execute(callback, { action: "refresh" });
                    }
                }}
            >
                <CreateOrEditQuestionForm
                    ref={editFormRef}
                    data={currentQuestion}
                />
            </FormDialog>
            <FormDialog
                ref={deleteFormDialogRef}
                title={`Xoá Câu hỏi`}
                cancelButtonLabel={"Đóng"}
                submitButtonLabel={"Xoá"}
                submitButtonIcon={"trash"}
                submitButtonVariant={"danger"}
                size={"lg"}
                onSubmit={() => {
                    return execute(deleteFormRef.current.submit);
                }}
                onClose={result => {
                    if (result) {
                        setCurrentQuestion(() => undefined);
                        return execute(callback, { action: "refresh" });
                    }
                }}
            >
                <DeleteQuestionForm
                    ref={deleteFormRef}
                    data={currentQuestion}
                />
            </FormDialog>

            <Tab.Container defaultActiveKey="0" onSelect={handleSelect}>
                <Card className="card-chat overflow-hidden">
                    <Card.Body as={Flex} className="p-0 h-100">
                        <div
                            className={classNames("chat-sidebar", {
                                "start-0": hideSidebar,
                            })}
                        >
                            <div className="contacts-list h-100">
                                <SimpleBarReact
                                    style={{ height: "100%", minWidth: "65px" }}
                                >
                                    <Nav className="border-0">
                                        {questions.map((question, index) => (
                                            <QuestionItem
                                                index={index}
                                                key={question?.id}
                                                data={question}
                                            />
                                        ))}
                                    </Nav>
                                </SimpleBarReact>
                            </div>
                            {/*<ChatContactsSearch/>*/}
                        </div>
                        <Tab.Content className="card-chat-content">
                            {currentQuestion && (
                                <QuestionContentHeader
                                    title={currentQuestion?.title}
                                    setHideSidebar={setHideSidebar}
                                    callback={handleCallback}
                                />
                            )}
                            {currentQuestion && (
                                <QuestionContentBody data={currentQuestion} />
                            )}
                            {!currentQuestion && (
                                <Flex
                                    className={
                                        "justify-content-center align-items-center h-100"
                                    }
                                >
                                    Không có dữ liệu
                                </Flex>
                            )}
                            {/*<MessageTextArea />*/}
                        </Tab.Content>
                    </Card.Body>
                </Card>
            </Tab.Container>
        </>
    );
};
QuizEditorBody.propTypes = {
    data: PropTypes.shape({
        quizId: PropTypes.number,
        questions: PropTypes.array,
    }),
    callback: PropTypes.func,
};

const QuizEditor = () => {
    const { id } = useParams();

    const editFormRef = useRef(null);

    const editFormDialogRef = useRef(null);

    const { getQuizProcessing, getQuizErrorMessage, getQuiz, quiz } = useQuiz();
    const {
        getQuestionsOfQuizProcessing,
        getQuestionsOfQuizErrorMessage,
        getQuestionsOfQuiz,
        questionsOfQuiz,
    } = useQuizQuestion();

    const [currentQuestion, setCurrentQuestion] = useState(null);

    const refreshPage = useCallback(
        async (inSilentMode = false) => {
            return await getQuestionsOfQuiz(id, inSilentMode);
        },
        [getQuestionsOfQuiz, id],
    );

    const handleCallback = useCallback(
        ({ action }) => {
            switch (action) {
                case "create":
                    setCurrentQuestion(() => ({ quizId: id }));
                    return execute(editFormDialogRef?.current?.showDialog);
                case "refresh":
                    return execute(refreshPage);
                default:
                    break;
            }
        },
        [id, refreshPage],
    );

    usePostCallback(() => getQuiz(id), refreshPage);

    return (
        <>
            <FormDialog
                ref={editFormDialogRef}
                title={`${currentQuestion ? "Cập nhật" : "Tạo mới"} Câu hỏi`}
                size={"lg"}
                cancelButtonLabel={"Đóng"}
                submitButtonLabel={"Lưu"}
                secondarySubmitButtonLabel={"Thêm câu trả lời"}
                secondarySubmitButtonIcon={"add"}
                onSecondarySubmit={() => {
                    return editFormRef.current.addAnswerNumber();
                }}
                onSubmit={async () => {
                    return editFormRef.current.submit();
                }}
                onClose={result => {
                    if (result) {
                        delayExecute(300)(() => refreshPage(true));
                    }
                }}
            >
                <CreateOrEditQuestionForm
                    ref={editFormRef}
                    data={currentQuestion}
                />
            </FormDialog>

            <Card className="h-100">
                <Card.Header className="border-bottom border-200">
                    <QuizEditorHeader
                        title={`Biên soạn câu hỏi`}
                        subTitle={`Bài kiểm tra: ${quiz?.name}`}
                        callback={handleCallback}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <QuizEditorBody
                        data={{
                            quizId: _.toNumber(id),
                            questions: questionsOfQuiz,
                        }}
                        callback={handleCallback}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

export default QuizEditor;
