import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useResource from "@/hooks/biz/useResource";
import FormTextInput from "@/components/shared/FormTextInput";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormSelectInput from "@/components/shared/FormSelectInput";
import FormNumberInput from "@/components/shared/FormNumberInput";
import { youtubeUrlRegex } from "@/utils/regex";
import FormYoutubeUrlInput from "@/components/shared/FormYoutubeUrlInput";
import { resourceGroups } from "@/components/app/resources/utils";

const CreateOrEditResourceForm = forwardRef((props, ref) => {
    const { data } = props || {};
    const validationSchema = yup.object().shape({
        fileUrl: yup
            .string()
            .required("Đường dẫn Video Youtube không được để trống.")
            .url("Đường dẫn Video Youtube không hợp lệ.")
            .matches(youtubeUrlRegex, "Đường dẫn Video Youtube không hợp lệ."),
        note: yup.string(),
        resourceGroup: yup.string().required("Nhóm không được để trống."),
        orderNum: yup.number().min(0, "Thứ tự không được để trống."),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const {
        createResourceProcessing,
        createResourceErrorMessage,
        createResource,
        updateResourceProcessing,
        updateResourceErrorMessage,
        updateResource,
    } = useResource();

    const disabled = useMemo(() => {
        return createResourceProcessing || updateResourceProcessing;
    }, [createResourceProcessing, updateResourceProcessing]);

    const errorMessage = useMemo(() => {
        return createResourceErrorMessage || updateResourceErrorMessage;
    }, [createResourceErrorMessage, updateResourceErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues();
            return values?.id && values?.id > 0
                ? updateResource(values)
                : createResource(values);
        } else {
            return { error: errors };
        }
    }, [createResource, errors, getValues, updateResource]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormYoutubeUrlInput
                            register={register}
                            errors={errors}
                            accessor={"fileUrl"}
                            title={"Video Tư liệu (Youtube)"}
                            value={getValues("fileUrl")}
                            callback={handleValueChange("fileUrl")}
                            placeholder={
                                "Nhập hoặc Copy/Paste đường dẫn Video Youtube"
                            }
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"note"}
                            title={"Ghi chú"}
                            value={getValues("note")}
                            callback={handleValueChange("note")}
                            placeholder={"Nhập Ghi chú"}
                            required={false}
                            disabled={disabled}
                        />

                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"resourceGroup"}
                            title={"Nhóm"}
                            value={getValues("resourceGroup")}
                            nonValue={""}
                            defaultValue={"movie"}
                            refData={resourceGroups}
                            refAccessor={"resourceGroupName"}
                            callback={handleValueChange("resourceGroup")}
                            placeholder={"Nhóm"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormNumberInput
                            register={register}
                            errors={errors}
                            accessor={"orderNum"}
                            title={"Số Thứ tự"}
                            value={getValues("orderNum")}
                            callback={handleValueChange("orderNum")}
                            placeholder={"Nhập Số Thứ tự"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default CreateOrEditResourceForm;
