import React from "react";

import { Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

import RequiredSign from "@/components/extra/RequiredSign";
import { execute } from "@/utils/common";

const FormAnswerInput = ({
    register,
    errors,
    accessor,
    title,
    value,
    defaultValue,
    callback,
    extAccessor,
    extTitle,
    extValue,
    extDefaultValue,
    extCallback,
    deleteCallback,
    placeholder = "",
    required = false,
    disabled = false,
}) => {
    return (
        <Form.Group>
            <Form.Label>
                {title}: {required && <RequiredSign />}
            </Form.Label>
            <div style={{ position: "relative" }}>
                {" "}
                {/* Parent container */}
                <Form.Control
                    {...register(accessor)}
                    disabled={disabled}
                    type="text"
                    value={value || defaultValue || ""}
                    style={value && extAccessor ? { paddingRight: "8rem" } : {}}
                    onChange={e => execute(callback, e.target.value)}
                    isInvalid={!!errors[accessor]}
                    placeholder={placeholder}
                />
                {deleteCallback && !disabled && (
                    <MdDelete
                        className={"text-danger cursor-pointer"}
                        size={20}
                        onClick={e => execute(deleteCallback)}
                        style={{
                            position: "absolute",
                            top: "-1.5rem",
                            right: "0.0rem",
                        }}
                    />
                )}
                {value && extAccessor && (
                    <Form.Check
                        type="switch"
                        {...register(extAccessor)}
                        label={extTitle}
                        checked={extValue || extDefaultValue || false}
                        disabled={disabled}
                        onChange={e => execute(extCallback, e.target.checked)}
                        style={{
                            position: "absolute",
                            top: "0.35rem",
                            right: "0.5rem",
                        }}
                    />
                )}
            </div>

            <Form.Control.Feedback
                type="invalid"
                className={errors[accessor]?.message ? "block" : "hidden"}
            >
                {errors[accessor]?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormAnswerInput;
