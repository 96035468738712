import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Row } from "react-bootstrap";
import useResource from "@/hooks/biz/useResource";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteResourceForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData]);

    const {
        deleteResourceProcessing,
        deleteResourceErrorMessage,
        deleteResource,
    } = useResource();

    const errorMessage = useMemo(() => {
        return deleteResourceErrorMessage;
    }, [deleteResourceErrorMessage]);

    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteResource(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteResource]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage} />
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Video Tư liệu đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteResourceForm;
