import Divider from "@/components/common/Divider";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "@/hooks/useAuth";
import Lottie from "lottie-react";
import loadingSquare from "@/assets/images/animated-icons/loading-square.json";
import IconAlert from "@/components/common/IconAlert";

const LoginForm = ({ hasLabel }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { auth, loginProcessing, loginErrorMessage, login } = useAuth();

    const [formData, setFormData] = useState({
        email: auth?.user?.email || "admin@seap.vn",
        password: "123456",
        remember: false,
    });

    const redirectUrl = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        const redirectUrl = queryParams.get("redirect");
        if (redirectUrl) return redirectUrl;
        else return "/";
    }, [location.search]);

    useEffect(() => {}, [auth?.jwt?.accessToken]);

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();
        const { email, password } = formData;
        const { result, error } = (await login(email, password)) || {};
        if (error) {
            toast.error(`Đăng nhập không thành công ${email}`, {
                theme: "colored",
            });
        } else {
            const { jwt, user } = result;
            toast.success(`Đăng nhập thành công ${user?.email || ""}`, {
                theme: "colored",
            });
            navigate(redirectUrl);
        }
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            {loginProcessing && (
                <Row className="d-flex justify-content-center my-3">
                    <Col lg={4} className="d-flex justify-content-center">
                        <Lottie
                            animationData={loadingSquare}
                            loop={true}
                            style={{ height: "72px", width: "72px" }}
                        />
                    </Col>
                </Row>
            )}

            {loginErrorMessage && (
                <IconAlert
                    className="mx-0 my-0"
                    variant={"warning"}
                    dismissible={false}
                >
                    <p className="mb-0">
                        {"Vui lòng kiểm tra lại thông tin đăng nhập"}
                    </p>
                </IconAlert>
            )}

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? "Email" : ""}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Mật khẩu</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? "Mật khẩu" : ""}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>

            <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                    <Form.Check
                        type="checkbox"
                        id="rememberMe"
                        className="mb-0"
                    >
                        <Form.Check.Input
                            type="checkbox"
                            name="remember"
                            checked={formData.remember}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    remember: e.target.checked,
                                })
                            }
                        />
                        <Form.Check.Label className="mb-0 text-700">
                            Ghi nhớ tên đăng nhập
                        </Form.Check.Label>
                    </Form.Check>
                </Col>

                <Col xs="auto">
                    <Link className="fs-10 mb-0" to={`/auth/forgot-password`}>
                        Quên mật khẩu?
                    </Link>
                </Col>
            </Row>

            <Form.Group>
                <Button
                    type="submit"
                    color="primary"
                    className="mt-3 w-100"
                    disabled={
                        !formData.email || !formData.password || loginProcessing
                    }
                >
                    Đăng nhập
                </Button>
            </Form.Group>

            <Divider className="mt-4">hoặc đăng nhập với</Divider>

            <Button
                color="secondary"
                variant="secondary"
                className="mt-3 w-100"
                disabled={loginProcessing}
            >
                Google
            </Button>
        </Form>
    );
};

LoginForm.propTypes = {
    hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
    hasLabel: false,
};

export default LoginForm;
