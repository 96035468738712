import { toNumber } from "@/utils/common";

export const toAnswers = (data, answerNumbers) => {
    const answers = [];
    for (const answerNumber of answerNumbers) {
        answers.push({
            id: data[`answer${answerNumber}Id`],
            content: data[`answer${answerNumber}Content`],
            isCorrect: data[`answer${answerNumber}Correct`] ? 1 : 0,
            answerType: toNumber(data[`answer${answerNumber}Type`], 1),
            dataFormat: toNumber(data[`answer${answerNumber}DataFormat`], 0),
            dataMaxLength: toNumber(
                data[`answer${answerNumber}DataMaxLength`],
                0,
            ),
            dataSource: data[`answer${answerNumber}DataSource`] ? 1 : 0,
        });
    }

    return answers;
};

export const fromAnswers = (answers = []) => {
    const data = {};
    for (let index = 0; index < answers.length; index++) {
        const answer = answers[index];
        data[`answer${index + 1}Id`] = answer?.id;
        data[`answer${index + 1}Content`] = answer?.content;
        data[`answer${index + 1}Correct`] = !!answer?.isCorrect;
        data[`answer${index + 1}Type`] = toNumber(answer?.answerType, 1);
        data[`answer${index + 1}DataFormat`] = toNumber(answer?.dataFormat, 0);
        data[`answer${index + 1}DataMaxLength`] = toNumber(
            answer?.dataMaxLength,
            0,
        );
        data[`answer${index + 1}DataSource`] = answer?.dataSource;
    }
    return data;
};

export const getAnswerNumbers = (answers = []) => {
    const numbers = [];
    for (let index = 0; index < answers.length; index++) {
        numbers.push(index + 1);
    }

    return numbers;
};
