import React from "react";

import { Form, FormControl } from "react-bootstrap";

import RequiredSign from "@/components/extra/RequiredSign";
import { execute, toNumber } from "@/utils/common";

const FormNumberInput = ({
    register,
    errors,
    accessor,
    title,
    value,
    defaultValue,
    callback,
    placeholder = "",
    required = false,
    disabled = false,
}) => {
    return (
        <Form.Group>
            <Form.Label>
                {title}: {required && <RequiredSign />}
            </Form.Label>
            <Form.Control
                {...register(accessor)}
                disabled={disabled}
                type="number"
                value={value || defaultValue || 0}
                onChange={e => execute(callback, toNumber(e.target.value, 0))}
                isInvalid={!!errors[accessor]}
                placeholder={placeholder}
            />
            <Form.Control.Feedback
                type="invalid"
                className={errors[accessor]?.message ? "block" : "hidden"}
            >
                {errors[accessor]?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormNumberInput;
