import {useCallback} from "react";
import { setCookie, getCookie , removeCookie } from 'react-use-cookie';
import hash from 'object-hash';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const COOKIE_OPTIONS = {
  days: 10,
  Secure: true,
};


const useCookieStorage = () => {
  const getItem = useCallback( (key, initialValue = undefined) => {
    const storedValue = getCookie(hash(`${SECRET_KEY}_${key}`), undefined);
    if(!storedValue) return initialValue;
    try{
      return JSON.parse(storedValue);
    } catch (e) {
      return initialValue;
    }
  }, []);

  const setItem = useCallback( (key, value) => {
    setCookie(hash(`${SECRET_KEY}_${key}`), JSON.stringify(value), COOKIE_OPTIONS);
  }, []);

  const removeItem = useCallback( (key) => {
    removeCookie(hash(`${SECRET_KEY}_${key}`));
  }, []);

  return {
    getItem,
    setItem,
    removeItem,
  };
};

export default useCookieStorage;
