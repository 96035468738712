import React from "react";

import IconAlert from "@/components/common/IconAlert";

const FormErrorMessage = ({ errorMessage }) => {
    return (
        <>
            {errorMessage && (
                <IconAlert
                    className="mx-0 my-0"
                    variant={"warning"}
                    dismissible={false}
                >
                    <p className="mb-0">{errorMessage}</p>
                </IconAlert>
            )}
        </>
    );
};

export default FormErrorMessage;
